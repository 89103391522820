<template>
  <div>
    <v-row>
      <v-col cols="12" md="5">
        <v-simple-table class="table-padding-2-no-top">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th colspan="3" class="text-h6">
                  {{ $t("labels.delivery_contract") }}
                </th>
                <th class="text-right">
                  <v-btn
                    color="primary"
                    small
                    @click="showDeliveryContract({})"
                    >{{ $t("labels.add") }}</v-btn
                  >
                </th>
              </tr>
            </thead>
            <thead class="v-data-table-header">
              <tr>
                <th class="text-center">{{ $t("labels.code") }}</th>
                <th class="text-center">{{ $t("labels.delivery_company") }}</th>
                <th class="text-center">{{ $t("labels.status") }}</th>
                <th class="text-center">{{ $t("labels.api_fee") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="dContract in defaultContracts"
                :key="`dc_${dContract.id}`"
              >
                <td class="text-center">
                  <div v-if="dContract.warehouse_code">
                    {{ $t("labels.warehouse_1") }}:
                    {{ dContract.warehouse_code }}
                  </div>
                  <div v-if="dContract.pos_code">
                    {{ $t("labels.pos") }}: {{ dContract.pos_code }}
                  </div>
                </td>
                <td class="text-center">
                  {{ dContract.delivery_company_short_name }}
                </td>
                <td class="text-center vertical-align-middle">
                  <div class="d-flex align-center justify-center">
                    <v-checkbox
                      v-model="dContract.status"
                      disabled
                      class="mt-0 ml-1"
                      :value="1"
                      hide-details
                    ></v-checkbox>
                  </div>
                </td>
                <td class="text-center">
                  {{ formatNumber(dContract.api_cost || 0) }}đ
                </td>
              </tr>
              <tr class="grey--text">
                <td class="text-center">
                  <div v-if="defaultWarehouseCodes">
                    {{ $t("labels.warehouse_1") }}: {{ defaultWarehouseCodes }}
                  </div>
                  <div v-if="defaultPosCodes">
                    {{ $t("labels.pos") }}: {{ defaultPosCodes }}
                  </div>
                </td>
                <td class="text-center">
                  {{ $t("labels.delivery_optimize") }} <br />
                  (+{{ $t("labels.price_per_order", { price: 200 }) }})
                </td>
                <td class="text-center vertical-align-middle">
                  <div class="d-flex align-center justify-center">
                    <v-checkbox
                      :disabled="true"
                      class="mt-0 ml-1"
                      :value="1"
                      hide-details
                    ></v-checkbox>
                  </div>
                </td>
                <td class="text-center">
                  {{ $t("labels.price_per_order", { price: 300 }) }}
                </td>
              </tr>
              <tr
                v-for="cContract in customerContracts"
                :key="`cc_${cContract.id}`"
              >
                <td class="text-center">
                  {{ cContract.warehouse_code || cContract.pos_code }}
                </td>
                <td class="text-center">
                  <span
                    class="cursor-pointer primary--text text-decoration-underline"
                    @click="showDeliveryContract(cContract)"
                  >
                    {{ cContract.delivery_company_short_name }}
                  </span>
                </td>
                <td class="text-center vertical-align-middle">
                  <div class="d-flex align-center justify-center">
                    <v-checkbox
                      v-model="cContract.status"
                      class="mt-0 ml-1"
                      :value="1"
                      hide-details
                    ></v-checkbox>
                  </div>
                </td>
                <td class="text-center">
                  {{
                    $t("labels.price_per_order", {
                      price: cContract.api_cost || 0,
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6">
        <v-simple-table class="table-padding-2-no-top">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th colspan="5" class="text-h6">
                  <div class="d-flex align-center justify-space-between">
                    <span>{{ $t("labels.delivery_config") }}</span>
                    <v-btn
                      x-small
                      color="warning"
                      @click="showWeightConfigDialog"
                      >{{ $t("labels.weight_config") }}</v-btn
                    >
                  </div>
                </th>
              </tr>
            </thead>
            <thead class="v-data-table-header">
              <tr>
                <th class="text-center" style="min-width: 60px">
                  {{ $t("labels.code") }}
                </th>
                <th class="text-center">{{ $t("labels.parameter") }}</th>
                <th class="text-center">
                  {{ $t("labels.domestic_province") }}
                </th>
                <th class="text-center">{{ $t("labels.inter_province") }}</th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody
              v-for="(
                customerDeliveryContract, cdck
              ) in customerDeliveryContracts"
              :key="`cdc_${customerDeliveryContract.id}`"
            >
              <tr v-if="cdck > 0">
                <td colspan="5"></td>
              </tr>
              <tr>
                <td
                  class="text-center font-weight-medium"
                  :rowspan="
                    isNotDefaultContract(customerDeliveryContract) ? 6 : 1
                  "
                >
                  <span v-if="customerDeliveryContract.warehouse_code">
                    {{ $t("labels.warehouse_1") }} <br />
                    {{ customerDeliveryContract.warehouse_code }}
                  </span>
                  <span v-if="customerDeliveryContract.pos_code">
                    {{ $t("labels.pos") }} <br />
                    {{ customerDeliveryContract.pos_code }}
                  </span>
                </td>
                <td class="text-center">{{ $t("labels.delivery_type") }}</td>
                <td class="text-center">
                  <v-autocomplete
                    class="c-input-xs"
                    v-model="
                      customerDeliveryContract.id_delivery_contract_provincial
                    "
                    :items="
                      deliveryOptions(customerDeliveryContract, typeProvincial)
                    "
                    :label="$t('labels.delivery_company')"
                    :placeholder="$t('labels.delivery_company')"
                    @change="getMinWeight(customerDeliveryContract, cdck)"
                    dense
                    outlined
                    hide-details
                  ></v-autocomplete>
                </td>
                <td class="text-center">
                  <v-autocomplete
                    class="c-input-xs"
                    v-model="
                      customerDeliveryContract.id_delivery_contract_inter_provincial
                    "
                    :items="
                      deliveryOptions(
                        customerDeliveryContract,
                        typeInterProvincial
                      )
                    "
                    :label="$t('labels.delivery_company')"
                    :placeholder="$t('labels.delivery_company')"
                    @change="getMinWeight(customerDeliveryContract, cdck)"
                    dense
                    outlined
                    hide-details
                  ></v-autocomplete>
                </td>
                <td
                  class="text-center"
                  :rowspan="
                    isNotDefaultContract(customerDeliveryContract) ? 6 : 1
                  "
                >
                  <v-btn
                    :disabled="
                      isDisabledBtnCustomerContract(customerDeliveryContract)
                    "
                    color="success"
                    small
                    @click="saveCustomerContract(customerDeliveryContract)"
                    >{{ $t("labels.save") }}</v-btn
                  >
                </td>
              </tr>
              <template v-if="isNotDefaultContract(customerDeliveryContract)">
                <tr>
                  <td class="text-center">
                    {{ $t("labels.auto_shipping_change") }}
                  </td>
                  <td class="text-center vertical-align-middle">
                    <div class="d-flex align-center justify-center">
                      <v-checkbox
                        v-model="
                          customerDeliveryContract.auto_change_delivery_company_provincial
                        "
                        :disabled="
                          defaultContractIds.includes(
                            customerDeliveryContract.id_delivery_contract_provincial
                          ) || customerDeliveryContract.length < 2
                        "
                        class="mt-0 ml-1"
                        :value="1"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </td>
                  <td class="text-center vertical-align-middle">
                    <div class="d-flex align-center justify-center">
                      <v-checkbox
                        v-model="
                          customerDeliveryContract.auto_change_delivery_company_inter_provincial
                        "
                        :disabled="
                          defaultContractIds.includes(
                            customerDeliveryContract.id_delivery_contract_inter_provincial
                          ) || customerDeliveryContract.length < 2
                        "
                        class="mt-0 ml-1"
                        :value="1"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    {{ $t("labels.ssc_shipping") }} <br />
                    ({{ $t("labels.when_needed") }})
                  </td>
                  <td class="text-center vertical-align-middle">
                    <div class="d-flex align-center justify-center">
                      <v-checkbox
                        v-model="
                          customerDeliveryContract.ssc_delivery_company_provincial
                        "
                        :disabled="
                          defaultContractIds.includes(
                            customerDeliveryContract.id_delivery_contract_provincial
                          )
                        "
                        class="mt-0 ml-1"
                        :value="1"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </td>
                  <td class="text-center vertical-align-middle">
                    <div class="d-flex align-center justify-center">
                      <v-checkbox
                        v-model="
                          customerDeliveryContract.ssc_delivery_company_inter_provincial
                        "
                        :disabled="
                          defaultContractIds.includes(
                            customerDeliveryContract.id_delivery_contract_inter_provincial
                          )
                        "
                        class="mt-0 ml-1"
                        :value="1"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    {{ $t("labels.reduce_weight") }} <br />
                    ({{ $t("labels.price_per_order", { price: 300 }) }})
                  </td>
                  <td class="text-center">
                    <v-text-field
                      :disabled="
                        defaultContractIds.includes(
                          customerDeliveryContract.id_delivery_contract_provincial
                        )
                      "
                      v-model.number="
                        customerDeliveryContract.decrease_weight_provincial
                      "
                      class="c-input-small"
                      :placeholder="$t('labels.gram')"
                      type="number"
                      append-icon="g"
                      outlined
                      dense
                      hide-details
                      single-line
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      :disabled="
                        defaultContractIds.includes(
                          customerDeliveryContract.id_delivery_contract_inter_provincial
                        )
                      "
                      v-model.number="
                        customerDeliveryContract.decrease_weight_inter_provincial
                      "
                      class="c-input-small"
                      :placeholder="$t('labels.gram')"
                      type="number"
                      append-icon="g"
                      outlined
                      dense
                      hide-details
                      single-line
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    {{ $t("labels.fixed_weight") }} <br />
                    ({{ $t("labels.price_per_order", { price: 500 }) }})
                  </td>
                  <td class="text-center">
                    <v-text-field
                      :disabled="
                        defaultContractIds.includes(
                          customerDeliveryContract.id_delivery_contract_provincial
                        )
                      "
                      v-model.number="
                        customerDeliveryContract.fixed_weight_provincial
                      "
                      class="c-input-small"
                      :placeholder="$t('labels.gram')"
                      type="number"
                      append-icon="g"
                      outlined
                      dense
                      hide-details
                      single-line
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-text-field
                      :disabled="
                        defaultContractIds.includes(
                          customerDeliveryContract.id_delivery_contract_inter_provincial
                        )
                      "
                      v-model.number="
                        customerDeliveryContract.fixed_weight_inter_provincial
                      "
                      class="c-input-small"
                      :placeholder="$t('labels.gram')"
                      type="number"
                      append-icon="g"
                      outlined
                      dense
                      hide-details
                      single-line
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="text-center border-bottom-thin">
                    {{ $t("labels.minimum_weight") }}
                  </td>
                  <td
                    class="text-center border-bottom-thin error--text font-italic"
                  >
                    {{
                      formatNumber(customerDeliveryContract.minWeightProvincial)
                    }}
                    gram
                  </td>
                  <td
                    class="text-center border-bottom-thin error--text font-italic"
                  >
                    {{
                      formatNumber(
                        customerDeliveryContract.minWeightInterProvincial
                      )
                    }}
                    gram
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-dialog v-model="contractDialog" max-width="400px">
      <DeliveryContract
        v-if="contractDialog"
        :updating-item="updatingItem"
        @cancel="hideDeliveryContract"
        @refreshData="getContracts"
      />
    </v-dialog>

    <v-dialog v-model="weightConfigDialog" max-width="860px">
      <WeightConfig
        v-if="weightConfigDialog"
        @cancel="hideWeightConfigDialog"
        :contracts="contracts"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  DEFAULT_CONTRACT_IDS,
  SSC_DELIVERY_ID,
  TVC_DELIVERY_ID,
  BY_WEIGHT_DELIVERY_ID,
  DELIVERY_TYPE_PROVINCIAL,
  DELIVERY_TYPE_INTER_PROVINCIAL,
} from "@/libs/const";

export default {
  name: "DeliveryConfig",
  components: {
    DeliveryContract: () => import("@/components/account/DeliveryContract.vue"),
    WeightConfig: () => import("@/components/account/WeightConfig.vue"),
  },
  data: () => ({
    isLoading: false,
    contractDialog: false,
    weightConfigDialog: false,
    updatingItem: {},
    warehouses: [],
    poss: [],
    contracts: [],
    customerDeliveryContracts: [],
    weightConfigs: [],
    typeProvincial: DELIVERY_TYPE_PROVINCIAL,
    typeInterProvincial: DELIVERY_TYPE_INTER_PROVINCIAL,
  }),
  computed: {
    defaultWarehouseCodes() {
      return [...this.warehouses].map((w) => w.code_name).join("; ");
    },
    defaultPosCodes() {
      return [...this.poss].map((p) => p.code).join("; ");
    },
    defaultContractIds() {
      return DEFAULT_CONTRACT_IDS;
    },
    defaultContracts() {
      return [
        {
          id: SSC_DELIVERY_ID,
          delivery_company_short_name: "SSC",
          status: 1,
          api_cost: 0,
          warehouse_code: this.defaultWarehouseCodes,
          pos_code: this.defaultPosCodes,
        },
        {
          id: TVC_DELIVERY_ID,
          delivery_company_short_name: this.$t("labels.tvc"),
          status: 1,
          api_cost: 0,
          warehouse_code: this.defaultWarehouseCodes,
          pos_code: this.defaultPosCodes,
        },
      ];
    },
    customerContracts() {
      return this.contracts; //.filter(c => !DEFAULT_SSC_DELIVERY_CONTRACTS.includes(c.id_delivery_company))
    },
  },
  watch: {},
  async mounted() {
    this.getWarehousesPoss();
    await this.getContracts();
    await this.getCustomerContract();
    await this.getWeightConfigGroup();
  },
  methods: {
    isDisabledBtnCustomerContract(customerDeliveryContract) {
      return (
        (customerDeliveryContract.decrease_weight_provincial &&
          customerDeliveryContract.decrease_weight_provincial <
            customerDeliveryContract.minWeightProvincial) ||
        (customerDeliveryContract.decrease_weight_inter_provincial &&
          customerDeliveryContract.decrease_weight_inter_provincial <
            customerDeliveryContract.minWeightInterProvincial)
      );
    },
    deliveryOptions(item, type) {
      const customerContracts = [...this.customerContracts].filter(
        (cc) =>
          cc.id_warehouse === item.id_warehouse && cc.id_pos === item.id_pos
      );
      const options = [...this.defaultContracts, ...customerContracts].map(
        (d) => ({
          text: d.delivery_company_short_name,
          value: d.id,
        })
      );
      if (customerContracts.length > 1) {
        const checkConfig = [...this.weightConfigs].find(
          (cc) =>
            cc.id_warehouse === item.id_warehouse &&
            cc.id_pos === item.id_pos &&
            cc.type === type
        );
        if (checkConfig && checkConfig.count_id > 1) {
          options.push({
            value: BY_WEIGHT_DELIVERY_ID,
            text: this.$t("labels.delivery_by_weight"),
          });
        }
      }
      return options;
    },
    isNotDefaultContract(customerDeliveryContract) {
      return (
        !this.defaultContractIds.includes(
          customerDeliveryContract.id_delivery_contract_provincial
        ) ||
        !this.defaultContractIds.includes(
          customerDeliveryContract.id_delivery_contract_inter_provincial
        )
      );
    },
    getWarehousesPoss() {
      const { warehouses, poss } = window.me;
      this.warehouses = [...warehouses];
      this.poss = [...poss];
    },
    showDeliveryContract(item) {
      this.updatingItem = { ...item };
      this.contractDialog = true;
    },
    hideDeliveryContract() {
      this.updatingItem = {};
      this.contractDialog = false;
    },
    showWeightConfigDialog() {
      this.weightConfigDialog = true;
    },
    hideWeightConfigDialog() {
      this.weightConfigDialog = false;
      this.getWeightConfigGroup();
    },
    async getWeightConfigGroup() {
      const { data } = await httpClient.post("/group-weight-config");
      this.weightConfigs = [...data];
      return true;
    },
    async getContracts() {
      const { data } = await httpClient.post("/delivery-contract-list");
      this.contracts = [...data.rows];
      return true;
    },
    async getMinWeight(customerDeliveryContract, index) {
      if (
        this.defaultContractIds.includes(
          customerDeliveryContract.id_delivery_contract_provincial
        )
      ) {
        customerDeliveryContract = {
          ...customerDeliveryContract,
          decrease_weight_provincial: null,
          fixed_weight_provincial: null,
          minWeightProvincial: 0,
        };
        if (
          customerDeliveryContract.id_delivery_contract_provincial ===
          TVC_DELIVERY_ID
        ) {
          customerDeliveryContract = {
            ...customerDeliveryContract,
            auto_change_delivery_company_provincial: 0,
            ssc_delivery_company_provincial: 0,
          };
        } else if (
          customerDeliveryContract.id_delivery_contract_provincial ===
          SSC_DELIVERY_ID
        ) {
          customerDeliveryContract = {
            ...customerDeliveryContract,
            auto_change_delivery_company_provincial: 1,
            ssc_delivery_company_provincial: 1,
          };
        }
      } else {
        const { data } = await httpClient.post(
          "/delivery-contract-default-by-ssc",
          {
            id_delivery_contract:
              customerDeliveryContract.id_delivery_contract_provincial,
            id_warehouse: customerDeliveryContract.id_warehouse,
            id_pos: customerDeliveryContract.id_pos,
          }
        );
        customerDeliveryContract.minWeightProvincial =
          (data && data.min_weight_after_decrease_provincial) || 0;
      }

      if (
        this.defaultContractIds.includes(
          customerDeliveryContract.id_delivery_contract_inter_provincial
        )
      ) {
        customerDeliveryContract = {
          ...customerDeliveryContract,
          decrease_weight_inter_provincial: null,
          fixed_weight_inter_provincial: null,
          minWeightInterProvincial: 0,
        };
        if (
          customerDeliveryContract.id_delivery_contract_inter_provincial ===
          TVC_DELIVERY_ID
        ) {
          customerDeliveryContract = {
            ...customerDeliveryContract,
            auto_change_delivery_company_inter_provincial: 0,
            ssc_delivery_company_inter_provincial: 0,
          };
        } else if (
          customerDeliveryContract.id_delivery_contract_inter_provincial ===
          SSC_DELIVERY_ID
        ) {
          customerDeliveryContract = {
            ...customerDeliveryContract,
            auto_change_delivery_company_inter_provincial: 1,
            ssc_delivery_company_inter_provincial: 1,
          };
        }
      } else {
        const { data } = await httpClient.post(
          "/delivery-contract-default-by-ssc",
          {
            id_delivery_contract:
              customerDeliveryContract.id_delivery_contract_inter_provincial,
            id_warehouse: customerDeliveryContract.id_warehouse,
            id_pos: customerDeliveryContract.id_pos,
          }
        );
        customerDeliveryContract.minWeightInterProvincial =
          (data && data.min_weight_after_decrease_inter_provincial) || 0;
      }
      const customerDeliveryContracts = [...this.customerDeliveryContracts];
      customerDeliveryContracts[index] = { ...customerDeliveryContract };
      this.customerDeliveryContracts = [...customerDeliveryContracts];
    },
    getCustomerContract() {
      httpClient.post("/customer-delivery-contract").then(({ data }) => {
        const customerDeliveryContracts = [];
        data.forEach((d) => {
          const customerDeliveryContract = {
            id: d.id,
            id_warehouse: d.id_warehouse,
            warehouse_code: d.warehouse_code,
            id_pos: d.id_pos,
            pos_code: d.pos_code,
            id_delivery_contract_provincial:
              d.id_delivery_contract_provincial || SSC_DELIVERY_ID,
            auto_change_delivery_company_provincial:
              d.auto_change_delivery_company_provincial || 0,
            ssc_delivery_company_provincial:
              d.ssc_delivery_company_provincial || 0,
            decrease_weight_provincial: d.decrease_weight_provincial || null,
            fixed_weight_provincial: d.fixed_weight_provincial || null,

            id_delivery_contract_inter_provincial:
              d.id_delivery_contract_inter_provincial || SSC_DELIVERY_ID,
            auto_change_delivery_company_inter_provincial:
              d.auto_change_delivery_company_provincial || 0,
            ssc_delivery_company_inter_provincial:
              d.ssc_delivery_company_inter_provincial || 0,
            decrease_weight_inter_provincial:
              d.decrease_weight_inter_provincial || null,
            fixed_weight_inter_provincial:
              d.fixed_weight_inter_provincial || null,
          };

          const checkProvincial = [
            ...this.deliveryOptions(d, this.typeProvincial),
          ].find(
            (d) =>
              d.value ===
              customerDeliveryContract.id_delivery_contract_provincial
          );
          if (!checkProvincial) {
            customerDeliveryContract.id_delivery_contract_provincial =
              SSC_DELIVERY_ID;
            customerDeliveryContract.auto_change_delivery_company_provincial = 1;
            customerDeliveryContract.ssc_delivery_company_provincial = 1;
          }

          const checkInterProvincial = [
            ...this.deliveryOptions(d, this.typeInterProvincial),
          ].find(
            (d) =>
              d.value ===
              customerDeliveryContract.id_delivery_contract_inter_provincial
          );
          if (!checkInterProvincial) {
            customerDeliveryContract.id_delivery_contract_inter_provincial =
              SSC_DELIVERY_ID;
            customerDeliveryContract.auto_change_delivery_company_inter_provincial = 1;
            customerDeliveryContract.ssc_delivery_company_inter_provincial = 1;
          }

          customerDeliveryContracts.push(customerDeliveryContract);
        });
        this.customerDeliveryContracts = [...customerDeliveryContracts];

        customerDeliveryContracts.forEach((c, i) => {
          this.getMinWeight(c, i);
        });
      });
    },
    async saveCustomerContract(customerDeliveryContract) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post(
          "/save-customer-contract",
          customerDeliveryContract
        );
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
